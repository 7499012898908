import { registerApplication, start } from 'single-spa';
import { I18nUtils } from './i18n/I18nUtils';

declare global {
  interface Window {
    InfinStorMlflowServer: string;
    InfinStorUserPoolId: string;
    InfinStorMlflowUiClientId: string;
    InfinStorIsExternalOauth: string;
    InfinStorApiServer: string;
    InfinStorAIgatewayServer: string;
    InfinStorServiceServer: string;
    InfinstorSingleSpaEnabled: string;
    projectID: string;
  }
}

I18nUtils.initI18n().then(() => {
  registerApplication(
    'root',
    () => import('./root.app'),
    () => true,
  );
  start();
});

const windowOnError = (message: any, source: any, lineno: any, colno: any, error: any) => {
  console.error(error, message);
  // returning false allows the default handler to fire as well
  return false;
};

window.onerror = windowOnError;
